import React from 'react';
import PropTypes from 'prop-types';
import styles from './_partner-section.module.scss';

function PartnersSection({ header, children }) {
    return <div className={styles['partners-section']}>
        <h2 className={styles['partners-section__header']}>{header}</h2>
        <ul className={styles['partners-section__items']}>
            {children}
        </ul>
    </div>;
}

PartnersSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default PartnersSection;