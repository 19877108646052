import React from 'react';
import PropTypes from 'prop-types';
import styles from './_solutions-section.module.scss';
import TextSection from '../TextSection/TextSection';

function SolutionsSection({ headerTheme, header, description, children }) {
    return <div className={styles['solutions-section']}>
        <div className={styles['solutions-section__header-container']}>
            <TextSection theme={headerTheme} title={header}
                text={description}
            />
        </div>
        <ul className={`${styles['solutions-section__items']} ul-no-style`}>
            {children}
        </ul>
    </div>;
}

SolutionsSection.propTypes = {
    headerTheme: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default SolutionsSection;