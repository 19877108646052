import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SLASHED_US_DATE_FORMAT } from '../../constants/formats';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import './_muiinput.scss';
import styles from './_muiinput.module.scss';

function MuiInputDatePicker({
    input,
    meta,
    formatString,
    label,
    readOnly,
    variant,
    inputVariant,
    fullWidth,
    id,
    minDate,
    maxDate,
    labelVariant
}) {
    useEffect(() => {
        setTouched(meta.touched);
    }, [meta.touched]);
    const [touched, setTouched] = useState(false);
    const handleClose = () => {
        setOpen(false);
        setTouched(true);
    };
    const handleOpen = () => {
        if (!readOnly) {
            setTouched(true);
            setOpen(true);
        }
    };
    const handleDateChange = (date) => {
        if (date === null) {
            onChange(date);
            return;
        }
        const momentDate = moment(date, formatString, true);
        if (momentDate.isValid()) {
            onChange(momentDate.format(formatString));
        }
    };
    const { error } = meta;
    const showError = error && touched;
    const { value, onChange } = input;
    const [open, setOpen] = useState(false);
    return <div className="mui-input-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant={variant}
                inputVariant={readOnly ? 'filled' : inputVariant}
                autoOk
                format={formatString}
                id={id}
                label={label}
                value={value ? new Date(value) : null}
                onChange={handleDateChange}
                fullWidth={fullWidth}
                readOnly={readOnly}
                minDate={minDate}
                maxDate={maxDate}
                error={showError}
                helperText={showError ? error : ''}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                open={open}
                onClose={handleClose}
                onClick={handleOpen}
                InputProps={{
                    readOnly,
                    className: readOnly ? styles.input_readOnly : '',
                }}
                InputLabelProps={{
                    variant: labelVariant,
                    className: readOnly ? styles.label_readOnly : ''
                }}
                InputAdornmentProps={{
                    classes: { root: readOnly ? styles.inputAdornment_readOnly : '' }
                }}
                PopoverProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    PaperProps: {
                        classes: { elevation8: styles.elevation }
                    }
                }}
            />
        </MuiPickersUtilsProvider>
    </div>;
}

MuiInputDatePicker.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
    }).isRequired,
    formatString: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    id: PropTypes.string,
    variant: PropTypes.string,
    inputVariant: PropTypes.string,
    fullWidth: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    labelVariant: PropTypes.string
};

MuiInputDatePicker.defaultProps = {
    formatString: SLASHED_US_DATE_FORMAT,
    variant: 'inline',
    inputVariant: 'outlined',
    fullWidth: true,
    labelVariant: 'outlined',
};

export default MuiInputDatePicker;
