import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './uploadMemberRecipientsForm.scss';
import uploadMemberRecipientsResources from '../uploadMemberRecipientsResources';
import resources from '../../../../Shared/resources';
import ConfirmPopup from '../../../../../components/Popups/ConfirmAlert/ConfirmPopup';
import { uploadMemberRecipients, showFileUploadErrorAlert } from '../../employeeManagementActions';
import { setUploadResults, setUploadResultsFileName } from '../../../../Shared/UploadRecipientsResults/uploadRecipientsResultsActions';
import moment from 'moment';
import { MONTH_DATE_FORMAT } from '../../../../../constants/formats';
import DragDropFileUploader from '../../../../../components/controls/DragDropFileUploader';

const UploadMemberRecipientsForm = ({
    partnerId,
    launchDate,
    uploadMemberRecipients,
    setUploadResults,
    showFileUploadErrorAlert
}) => {
    const [goodRecords, setGoodRecords] = useState([]);
    const [badRecords, setBadRecords] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [fileName, setFileName] = useState('');
    const history = useHistory();
    const fileSize = 25600;
    const uploadFile = (file) => {
        if (!file) {
            return;
        }
        if (file.size > fileSize) {
            showFileUploadErrorAlert(uploadMemberRecipientsResources.fileSizeError);
            return;
        }
        setGoodRecords([]);
        setBadRecords([]);
        setFileName(file.name);
        uploadMemberRecipients(partnerId, file)
            .then((response) => {
                const good = [];
                const bad = [];
                response.data.forEach((item) => {
                    if (item.reason === null || item.reason === '') {
                        good.push(item);
                    } else {
                        bad.push(item);
                    }
                }
                );
                setGoodRecords(good);
                setBadRecords(bad);
                setShowConfirmation(true);
            })
            .catch(() => {
                setShowConfirmation(false);
            });
    };

    const handleViewDetails = () => {
        setUploadResultsFileName(fileName);
        setUploadResults({ goodRecords, badRecords });
        setShowConfirmation(false);
        history.push('/uploadresults');
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    const totalRecords = () => goodRecords.length + badRecords.length;

    return (
        <>
            <ConfirmPopup showPopup={showConfirmation} body={
                <>
                    <h3 className="upload-member-recipients__title">{uploadMemberRecipientsResources.resultsPopupTitle}</h3>
                    <h4 className="upload-member-recipients__subtitle">{fileName}</h4>
                    <div className="popup-confirmation-panel">
                        <div className="col col-4 processed">{uploadMemberRecipientsResources.resultsProcessed}: {totalRecords()}</div>
                        <div className="col col-4 uploaded">{uploadMemberRecipientsResources.resultsUploaded}: {goodRecords.length}</div>
                        <div className="col col-4 failed">{uploadMemberRecipientsResources.resultsFailed}: {badRecords.length}</div>
                    </div>
                </>
            }
                onConfirm={badRecords && badRecords.length === 0 ? null : handleViewDetails}
                onCancel={handleCloseConfirmation}
                labelOk={uploadMemberRecipientsResources.buttonViewDetails}
                labelCancel={resources.closeButtonText}
                buttonSize="large"
            />
            <div className="grid__row info-box--grid__row">
                <div className="col-12">
                    <DragDropFileUploader
                        handleChange={uploadFile}
                        fileTypes={uploadMemberRecipientsResources.extensionAccepted}
                        name={uploadMemberRecipientsResources.memberRecipientsFileUploaderName}
                        title={uploadMemberRecipientsResources.uploadSectionTitle}
                        subtitle={uploadMemberRecipientsResources.uploadSectionDescription(moment(launchDate).format(MONTH_DATE_FORMAT))}
                    />
                </div>
            </div>
        </>
    );
};

UploadMemberRecipientsForm.propTypes = {
    partnerId: PropTypes.number.isRequired,
    launchDate: PropTypes.instanceOf(Date).isRequired,
    uploadMemberRecipients: PropTypes.func.isRequired,
    setUploadResults: PropTypes.func.isRequired,
    showFileUploadErrorAlert: PropTypes.func.isRequired,
};
const mapDispatchToProps = {
    uploadMemberRecipients,
    setUploadResults,
    showFileUploadErrorAlert
};

const connectStateToProps = connect(null, mapDispatchToProps);
export default connectStateToProps(UploadMemberRecipientsForm);
