import React, { useEffect } from 'react';
import routes from '../../../../routing/routes';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import CustomLink from '../../../../components/controls/CustomLink';
import styles from '../../../../styles/_info-page.module.scss';

function PrivacyArchives() {
    useEffect(() => {
        document.title = 'Privacy Policy Archive Index';
    }, []);
    return <div className={styles['info-page']}>
        <h1 className={styles['info-page__title']}>{HEALTHY_CONTRIBUTIONS_NAME} Privacy Policy Archives</h1>
        <div className={styles['info-page__content']}>
            <div className={styles['info-page__primary-content']}>
                <section className={styles['info-page__primary-content-section']}>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Below is a list of past Privacy Policies</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <div><CustomLink className={`${styles.link} ${styles['link--blue']} ${styles['link--overflow-wrap']}`} linkPath={routes.privacypolicy20230110.path}>Privacy Policy January 10, 2023</CustomLink></div>
                    <div><CustomLink className={`${styles.link} ${styles['link--blue']} ${styles['link--overflow-wrap']}`} linkPath={routes.californiaprivacypolicy20230110.path}>California Privacy Policy January 10, 2023</CustomLink></div>
                    <div><CustomLink className={`${styles.link} ${styles['link--blue']} ${styles['link--overflow-wrap']}`} linkPath={routes.privacynotice20230824.path}>Privacy Notice August 24, 2023</CustomLink></div>
                </section>
            </div>
        </div>
    </div>;
}

export default PrivacyArchives;