import { createSelector } from 'reselect';
import root from '../../routing/routesConfig';
import { authorizeRoutes } from '../../utilities/authorization';
import routes from '../../routing/routes';

export const getMe = (state) => { return state.app.get('me'); };

export const getIsLoading = (state) => {
    return state.app.get('isLoading');
};

export const getSelectedMemberGroup = state =>
    state.app.get('selectedMemberGroup');

export const getAuthorizedRoutes = (state) => {
    return state.app.get('authorizedRoutes').toJS();
};

const getFilteredRoutes = createSelector(
    [getMe, getIsLoading],
    (me, isLoading) => {
        return isLoading ? [] : authorizeRoutes(me, root.routes);
    }
);

const getPathsRecursive = function (routes) {
    if (!routes) {
        return [];
    }
    return routes.flatMap((route) => { return [route.path, ...getPathsRecursive(route.routes)]; });
};

const getAuthorizedPaths = createSelector(
    [getFilteredRoutes],
    (filteredRoutes) => {
        if (filteredRoutes && filteredRoutes.length === 0) {
            return [routes.root.path];
        }
        return [routes.root.path, ...getPathsRecursive(filteredRoutes)];
    }
);

const getDefinedPaths = () => {
    const definedPaths = [];
    for (const key in routes) {
        definedPaths.push(routes[key].path);
    }
    return definedPaths;
};

export default createSelector(
    [getIsLoading, getMe, getSelectedMemberGroup, getFilteredRoutes, getDefinedPaths, getAuthorizedPaths],
    (
        isLoading,
        me,
        selectedMemberGroup,
        filteredRoutes,
        definedPaths,
        authorizedPaths
    ) => ({
        isLoading,
        me,
        selectedMemberGroup,
        filteredRoutes,
        definedPaths,
        authorizedPaths
    })
);