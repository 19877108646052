import React from 'react';
import PropTypes from 'prop-types';
import HeaderPublic from '../components/Header/HeaderPublic';
import Topbar from '../components/Topbar/Topbar';
import FooterPublic from '../components/Footer/FooterPublic/FooterPublic';
import Sidebar from '../components/Sidebar/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { routeType } from '../routing/routes';
import classNames from 'classnames';
import './_layout.scss';
import './_skiplink.scss';
import LoginPopup from '../components/Login/LoginPopup';
import ForgotPasswordPopup from '../components/ForgotPassword/ForgotPasswordPopup';
import ForgotPasswordResultPopup from '../components/ForgotPassword/ForgotPasswordResultPopup';

const MainLayoutPublic = ({ children, match, history, topbarContent, filteredRoutes, hideSidebar }) => {
    return (
        <div className="container">
            <LoginPopup history={history} />
            <ForgotPasswordPopup />
            <ForgotPasswordResultPopup />
            <div className="skip-link-container">
                <a href="#main" className="skip-link">Skip to main content</a>
            </div>
            <HeaderPublic history={history} />
            <Topbar topbarContent={topbarContent} match={match}
                history={history}
            />
            <div className="layout">
                {hideSidebar ?
                    <></> :
                <Sidebar match={match}
                    filteredRoutes={filteredRoutes}
                />}
                <div id="main" role="main" tabIndex="-1" className={classNames([
                    'layout__content',
                    { 'layout__content--with-sidebar': !hideSidebar }])}
                >
                    <ErrorBoundary>
                        <div className="route-wrapper">{children}</div>
                    </ErrorBoundary>
                </div>
            </div>
            <FooterPublic />
        </div>
    );
};

MainLayoutPublic.propTypes = {
    children: PropTypes.node,
    topbarContent: PropTypes.object,
    filteredRoutes: PropTypes.arrayOf(routeType),
    match: PropTypes.object,
    history: PropTypes.object.isRequired,
    hideSidebar: PropTypes.bool
};

export default MainLayoutPublic;
