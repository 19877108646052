import React, { useEffect } from 'react';
import MetricsSection from '../../../../components/MetricsSection/MetricsSection';
import MetricsSectionItem from '../../../../components/MetricsSection/MetricsSectionItem';
import SectionsDivider from '../../../../components/SectionsDivider/SectionsDivider';
import SolutionsSectionItem from '../../../../components/SolutionsSection/SolutionsSectionItem';
import SolutionsSection from '../../../../components/SolutionsSection/SolutionsSection';
import routes from '../../../../routing/routes';
import TestimonialSection from '../../../../components/TestimonialSection/TestimonialSection';
import TestimonialSectionItem from '../../../../components/TestimonialSection/TestimonialSectionItem';
import optum from '../../../../assets/images/optum.svg';
import fitonhealth from '../../../../assets/images/FitOn-Health-logo.png';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import CallToActionImage from '../../../../components/CallToAction/CallToActionImage';
import guestAreaSharedResources from '../guestAreaSharedResources';
import { API_PATHS } from '../../../../http/configs';
import ash from '../../../../assets/images/ash.svg';
import PropTypes from 'prop-types';
import ContactUsSection from '../ContactUs/ContactUsSection';
import { ASH_ALT_TAG, OPTUM_ALT_TAG, FITON_HEALTH_ALT_TAG } from '../../../../constants/partnerImageGroups';
import MetaData from '../../../../components/MetaData/MetaData';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import styles from '../../../../styles/_public-page.module.scss';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';
import resources from './homeResources';

function Home({ history }) {
    function joinUsHandler() {
        window.location = API_PATHS.calendlyUrl;
    };

    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle(resources.title);
    }, []);

    return <div className={styles.public_page}>
        <MetaData keywords="healthy contributions"
            title={`${HEALTHY_CONTRIBUTIONS_NAME}, Call: ${HC_PHONE_NUMBER}`}
            description={`${HEALTHY_CONTRIBUTIONS_NAME} provides insurers & employers with wellness & fitness incentive programs, rewarding people for living healthier lives`}
        />
        <CallToAction>
            <CallToActionBody
                title={<>Improving health, one wellness program at a time</>}
                description={guestAreaSharedResources.requestDemoDescriptionText}
                buttonText={guestAreaSharedResources.requestDemoButtonText}
                buttonClickHandler={joinUsHandler}
            />
            <CallToActionImage image="groupYoga"
                imageAlt={guestAreaSharedResources.groupYogaImageAlt}
                imageClassName="group-yoga"
            />
        </CallToAction>
        <SectionsDivider />
        <MetricsSection header={<>Making it easy to live a healthy lifestyle</>}
            description="No other provider combines both wellness and data expertise, resulting in superior solutions that help people live healthier lives."
        >
            <MetricsSectionItem header="16k+" description="Fitness Network Partners" />
            <MetricsSectionItem header="11m+" description="Workouts Rewarded per year" />
            <MetricsSectionItem header="1.3m+" description="In processed, secure funds reimbursements" />
        </MetricsSection>
        <SectionsDivider />
        <SolutionsSection header={<>Wellness solutions for Everyone</>}
            description="Healthy Contributions has helped over 20,000+ fitness facilities and wellness partners create, implement, and sustain wellness programs that help people live healthier lives."
            headerTheme="column-on-large-screen"
        >
            <SolutionsSectionItem materialIconName="business_center" header="Fitness Partners"
                description="Let Healthy Contributions help manage the multiple fitness incentive programs that reward you and your members, through our user-friendly online portal."
                linkPath={routes.forFacilities.path}
            />
            <SolutionsSectionItem materialIconName="business" header="Employers"
                description="Healthy Contributions has the flexibility to customize a wellness program that achieves your organization’s wellness and budget goals."
                linkPath={routes.forEmployers.path}
            />
            <SolutionsSectionItem materialIconName="health_and_safety" header="Insurers"
                description="Manage your program safely with Healthy Contribution’s proprietary, secure, and HIPAA-compliant system."
                linkPath={routes.forInsurers.path}
            />
            <SolutionsSectionItem materialIconName="groups" header="Members"
                description="Enroll in your employer’s and/or insurance provider’s wellness program today and start the journey to a healthier you."
                linkPath={routes.forMembers.path}
            />
        </SolutionsSection>
        <SectionsDivider />
        <TestimonialSection header={<>What sets<br />Healthy Contributions apart</>}>
            <TestimonialSectionItem
                description="Optum has worked with Healthy Contributions since 2010 to serve as a payment processor for our network locations. The Healthy Contributions team that supports us has been flexible and reliable as our products have evolved and grown. They have enhanced their platform based on our business needs and from direct gym and fitness location feedback. I have had the pleasure of working closely with many of the support staff and they have been true partners that are responsive, kind, and dependable."
                logoName={optum}
                logoAlt={OPTUM_ALT_TAG}
                name="Vince Pozinski"
                position="Sr. Product Director at Optum"
                ariaLabel="slide 1 of 3"
            />
            <TestimonialSectionItem name="Kate Bosse"
                position="Director, Network Management"
                description="They truly take a partnership approach and deliver high quality service which in turn allows our members to have the best experience possible."
                logoName={fitonhealth}
                logoAlt={FITON_HEALTH_ALT_TAG}
                ariaLabel="slide 2 of 3"
            />
            <TestimonialSectionItem name="Jeff Na"
                position="Vice President of Fitness"
                logoName={ash}
                logoAlt={ASH_ALT_TAG}
                description="We have thoroughly enjoyed working with Health Contributions as they continue to be supportive and communicative with our Anytime Fitness partnership."
                ariaLabel="slide 3 of 3"
            />
        </TestimonialSection>
        <SectionsDivider />
        <ContactUsSection history={history}>
            <CallToActionImage image={'grandmaTutuBlue'}
                imageAlt={guestAreaSharedResources.grandmaGrandDaughterDancingImageAlt}
                imageClassName={'grandma-tutu-blue'}
            />
        </ContactUsSection>
        <SectionsDivider />
    </div>;
}

Home.propTypes = {
    history: PropTypes.object.isRequired
};

export default Home;