import React from 'react';
import PropTypes from 'prop-types';
import CustomArrow from './CustomArrow';
import styles from '../_slider.module.scss';

function NextArrow(props) {
    return (
        <CustomArrow className={styles['next-arrow']} icon="arrow_forward"
            onClick={props.onClick}
        />
    );
}

NextArrow.propTypes = {
    onClick: PropTypes.func
};

export default NextArrow;