import React from 'react';
import { NavLink } from 'react-router-dom';
import { API_PATHS } from '../../http/configs';
import routes from '../../routing/routes';

function AdminHeaderNavList() {
    return (
        <ul className="header__nav-list">
            <li className="header__nav-item">
                <a href={API_PATHS.HCDashboard}
                    className="new-link"
                >
                    dashboard
                </a>
            </li>
            <li className="header__nav-item">
                <a href={API_PATHS.HCFacilities}
                    className="new-link"
                >
                    facilities
                </a>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link header__nav-item--active"
                    to={routes.partnersList.path}
                >
                    partners
                </NavLink>
            </li>
            <li className="header__nav-item">
                <a href={API_PATHS.HCUsers}
                    className="new-link"
                >
                    users
                </a>
            </li>
            <li className="header__nav-item">
                <a href={API_PATHS.HCPlans}
                    className="new-link"
                >
                    plans
                </a>
            </li>
            <li className="header__nav-item">
                <a href={API_PATHS.HCSettings}
                    className="new-link"
                >
                    settings
                </a>
            </li>
        </ul>
    );
}

export default AdminHeaderNavList;
