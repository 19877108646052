import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/Image';
import styles from './_partner-section.module.scss';

function PartnersSectionItem({ imageObject, alt, link }) {
    const image = <Image alt={alt} image={imageObject}/>;
    return <li className={styles['partners-section__item']}>
        {link ? <a href={link}>{image}</a> : <div>{image}</div>}
    </li>;
}

PartnersSectionItem.propTypes = {
    imageObject: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    link: PropTypes.string
};

export default PartnersSectionItem;