import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Head from 'next/head';
import { GOOGLE_SEO_TAG, BING_SEO_TAG } from '../../constants/seo';
import { isServer } from '../../utilities/next';

function MetaData({ keywords, title, description }) {
    const metaTags = [
        <meta name="keywords" content={keywords} key="keywords" />,
        <meta name="title" content={title} key="title" />,
        <meta name="description" content={description} key="description" />,
        <meta name="google-site-verification" content={GOOGLE_SEO_TAG} key="google-site-verification" />,
        <meta name="msvalidate.01" content={BING_SEO_TAG} key="msvalidate.01" />
    ];
    return isServer() ?
        <Head>{metaTags}</Head>
        : <Helmet>{metaTags}</Helmet>;
}

MetaData.propTypes = {
    keywords: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};

export default MetaData;
