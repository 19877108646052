import React from 'react';
import PropTypes from 'prop-types';
import styles from './_text-section.module.scss';
import classNames from 'classnames';

function TextSection({ title, text, theme }) {
    return (
        <div className={classNames(styles['text-section'], styles['text-section__content-container'],
            { [styles[`text-section__content-container--${theme}`]] : theme })}
        >
            <h2 className={styles['text-section__title']}>
                {title}
            </h2>
            {text
                ?
                    <div className={styles['text-section__text']}>
                        {text}
                    </div>
                :
                <></>
            }
        </div>
    );
}
TextSection.propTypes = {
    title: PropTypes.element.isRequired,
    text: PropTypes.string,
    theme: PropTypes.string
};

export default TextSection;