import React from 'react';
import styles from './_slider.module.scss';

function CustomPaging(index) {
    const buttonId = `slider_${index}`;
    const dotSpanText = `Go to slide ${index + 1}`;

    function onClickHandler(e) {
        Array.from(document.getElementsByName('customDot')).forEach(
            function(element, index, array) {
                element.setAttribute('aria-current', 'false');
            }
        );

        e.currentTarget.setAttribute('aria-current', 'true');
    };

    return (
        <div>
            <button id={buttonId} className={styles['custom-dot']} aria-current="false" onClick={onClickHandler} name="customDot">
                <span className="sr-only">{dotSpanText}</span>
            </button>
        </div>
    );
}

export default CustomPaging;