import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Switch, withRouter, useLocation, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import CustomRoute from '../../layouts/CustomRoute';
import Spinner from '../../components/Spinner/Spinner';
import Notifications from '../../components/Notifications/Notifications';
import routes from '../../routing/routes';
import { authUserType } from '../../propTypes/customTypes';
import { checkIfPathMatchesAnyInCollection } from '../../utilities/authorization';
import appResources from './appResources';
import '../../../node_modules/material-icons/iconfont/outlined.scss';
import '../../../node_modules/material-icons/iconfont/filled.scss';

function App({
    isLoading,
    filteredRoutes,
    loadMe,
    definedPaths,
    authorizedPaths,
    unsetAppLoading,
    me
}) {
    useEffect(() => {
        loadMe();
    }, []);

    const location = useLocation();

    const [redirectRoutePath, setRedirectRoutePath] = useState();

    useEffect(() => {
        let redirectPath = routes.home.path;
        if (!isLoading) {
            const isDefinedPath = checkIfPathMatchesAnyInCollection(location.pathname, definedPaths);
            const isAuthorizedPath = checkIfPathMatchesAnyInCollection(location.pathname, authorizedPaths);

            if (!isAuthorizedPath && isDefinedPath) {
                const query = qs.stringify({ isLoginPopupOpen: true, error: appResources.unauthorizedPathErrorMessage, redirectUrl: location.pathname });
                redirectPath += `?${query}`;
            }
            setRedirectRoutePath(redirectPath);
        }
    }, [me, filteredRoutes]);

    return (
        <Fragment>
            {isLoading
                ? (<Spinner />)
                : (
                    <div>
                        <Switch>
                            {filteredRoutes.map((route, i) => (
                                <CustomRoute
                                    key={i}
                                    path={route.path}
                                    currentRoute={route}
                                    filteredRoutes={filteredRoutes}
                                />
                            ))}
                            {redirectRoutePath && <Redirect to={redirectRoutePath} />}
                        </Switch>
                    </div>)}
            <Notifications displayTimeout={5000} />
        </Fragment>
    );
}

App.propTypes = {
    isLoading: PropTypes.bool,
    loadMe: PropTypes.func,
    filteredRoutes: PropTypes.array,
    me: authUserType,
    authorizedPaths: PropTypes.arrayOf(PropTypes.string),
    definedPaths: PropTypes.arrayOf(PropTypes.string),
    unsetAppLoading: PropTypes.func,
};

export default withRouter(connect(selectors, actions)(App));