import React from 'react';
import PropTypes from 'prop-types';
import styles from './_metrics-section.module.scss';

function MetricsSectionItem({ header, description }) {
    return <li className={styles['metrics-section__item']}>
        <h3 className={styles['metrics-section__item-header']}>{header}</h3>
        <div className={styles['metrics-section__item-description']}>{description}</div>
    </li>;
}

MetricsSectionItem.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default MetricsSectionItem;