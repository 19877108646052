import { PRIVACY_NOTICE } from '../../constants/legal';

const footerResources = {
    linkNameForFacilities: 'For Facilities',
    linkNameForEmployers: 'For Employers',
    linkNameForPartner: 'Keyfob',
    linkNameForMembers: 'For Members',
    linkNameForInsurers: 'For Insurers',
    linkNameAbout: 'About us',
    linkNamePrivacyNotice: PRIVACY_NOTICE,
    linkNameConsumerHealthPrivacyNotice: 'Consumer Health Privacy Notice',
    linkNameTermsOfUse: 'Terms of Use',
    headerAllyant: 'Reviewed by Allyant',
    sectionNameSolutions: 'Solutions',
    sectionNameContact: 'Contact',
    sectionNameLegal: 'Legal',
    linkNameAccessibilityAndMaintenance: 'Accessibility & Maintenance',
    linkSiteMap: 'Site Map',
};
export default footerResources;