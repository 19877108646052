import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/Image';
import styles from './_call-to-action.module.scss';

function CallToActionImage({ image, imageAlt, imageClassName }) {
    return (
        <div className={styles['call-to-action__image-container']}>
            <Image className={`${styles['call-to-action__image']} ${styles[`call-to-action__image--${imageClassName}`]}`}
                image={image}
                alt={imageAlt}
            />
        </div>
    );
}

CallToActionImage.propTypes = {
    image:PropTypes.string.isRequired,
    imageAlt:PropTypes.string.isRequired,
    imageClassName:PropTypes.string.isRequired
};

export default CallToActionImage;
