import React from 'react';
import PropTypes from 'prop-types';
import Button from '../controls/Button';
import styles from './_call-to-action.module.scss';

function CallToActionBody({ title, subtitle, description, buttonText, buttonClickHandler, columns }) {
    return (
        <div className={`${styles['call-to-action__content-container']} ${styles[`call-to-action__content-container--columns-${columns}`]}`}>
            <div>
                {subtitle && <h3 className={styles['call-to-action__subtitle']}>
                    {subtitle}
                </h3>}
                <h2 className={`${styles['call-to-action__title']} ${styles[`call-to-action__title--columns-${columns}`]}`}>
                    {title}
                </h2>
            </div>
            <div className={styles['call-to-action__description-container']}>
                <div className={`${styles['call-to-action__description']} ${styles[`call-to-action__description--columns-${columns}`]}`}>
                    {description}
                </div>
                {buttonClickHandler && <div className={styles['call-to-action__button-container']}>
                    <Button className="btn_new btn_new--blue" role="link"
                        onClick={buttonClickHandler}
                    >
                        {buttonText}
                    </Button>
                </div>}
            </div>
        </div>
    );
}

CallToActionBody.defaultProps = {
    columns: 1
};

CallToActionBody.propTypes = {
    subtitle: PropTypes.string,
    title: PropTypes.element.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    buttonText: PropTypes.string,
    buttonClickHandler: PropTypes.func,
    columns: PropTypes.number
};

export default CallToActionBody;
