import React from 'react';
import CustomArrow from './CustomArrow';
import PropTypes from 'prop-types';
import styles from '../_slider.module.scss';

function PrevArrow(props) {
    return (
        <CustomArrow className={styles['prev-arrow']} icon="arrow_back"
            onClick={props.onClick}
        />
    );
}

PrevArrow.propTypes = {
    onClick: PropTypes.func
};
export default PrevArrow;