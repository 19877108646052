import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import actions from './actions';
import selectors from './selectors';
import Portal from '../Portal/Portal';
import { Notification } from './Notification';
import './_notifications.scss';

function Notifications(props) {
    const {
        notifications,
        clearNotification,
        displayTimeout
    } = props;

    return notifications.size > 0
        ? <Portal node={document.querySelector('#notification-root')}>
            <div className="notifications-container">
                {
                    notifications.map(notification =>
                        <Notification
                            key={notification.get('id')}
                            notification={notification}
                            displayTimeout={displayTimeout}
                            onClearNotification={clearNotification}
                        />
                    )
                }
            </div>
        </Portal>
        : null;
}

Notifications.propTypes = {
    notifications: PropTypes.instanceOf(List),
    displayTimeout: PropTypes.number,
    clearNotification: PropTypes.func
};

export default connect(selectors, actions)(Notifications);