import React, { useEffect } from 'react';
import routes from '../../../../routing/routes';
import { HEALTHY_CONTRIBUTIONS_LEGAL_NAME } from '../../../../constants/companyNames';
import { HEALTHY_CONTRIBUTIONS_PRIVACY } from '../../../../constants/emails';
import { HEALTHY_CONTRIBUTIONS, HEALTHY_CONTRIBUTIONS_WWW } from '../../../../constants/websites';
import styles from '../../../../styles/_info-page.module.scss';
import utilStyles from '../../../../styles/_utils.module.scss';
import privacyPolicyArchiveTitleFormatter from '../../../../utilities/formatters/privacyPolicyArchiveTitleFormatter';
import privacyPolicyEffectiveDateFormatter from '../../../../utilities/formatters/privacyPolicyEffectiveDateFormatter';
import CustomLink from '../../../../components/controls/CustomLink';

function CaliforniaPrivacyPolicy20230110() {
    const effectiveDate = new Date(2023, 0, 10);
    const formattedEffectiveDate = privacyPolicyEffectiveDateFormatter.formatPrivacyPolicyEffectiveDate(effectiveDate);
    useEffect(() => {
        document.title = `California ${privacyPolicyArchiveTitleFormatter.formatPrivacyPolicyTitle(effectiveDate)}`;
    }, []);
    return <div className={styles['info-page']}>
        <div className={styles['info-page__title']}>Privacy Notice for California Residents</div>
        <div className={styles['info-page__subtitle']}>Effective Date: {formattedEffectiveDate}</div>
        <div className={styles['info-page__content']}>
            <div className={styles['info-page__primary-content']}>
                <section className={styles['info-page__primary-content-section']}>
                    <div className={styles['info-page__primary-content-section-paragraph']}>To review our previous Privacy Policy, please refer to&nbsp;
                        <CustomLink
                            className={`${styles.link} ${styles['link--blue']} ${styles['link--overflow-wrap']}`}
                            linkPath={routes.privacynotice.path}
                        >
                            https://portal.healthycontributions.com/privacy
                        </CustomLink>. If you are a California resident, please read the following Privacy Notice for California Residents (“Policy”).</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>This Policy describes how information obtained from California consumers, including visitors to the Healthy Contributions SPV LLC (“Healthy Contributions”, “we”, “us” or “our”) website found at <a className={`${styles.link} ${styles['link--blue']}`} href={HEALTHY_CONTRIBUTIONS}>{HEALTHY_CONTRIBUTIONS_WWW}.</a> (“Website”) is collected, used, and disclosed. This Policy also explains how you can make privacy requests.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Terms included in this Policy, including but not limited to, personal information and service provider, carry the same meaning in this Policy as they do in the California Consumer Privacy Act of 2018, Cal. Civ. Code Section 1798.100, et seq., as may be amended from time to time, including but not limited to those amendments enacted by the California Privacy Rights Act of 2020 (“CPRA”) and any modifications thereof.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>These Terms of Use apply to your access to and use of this Site and do not alter in any way the terms and conditions of any other agreement you may have with us. If you breach any of these terms and conditions, your authorization to use this Site automatically terminates and you must immediately discontinue use of this Site.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="shine-light-law">
                    <div className={styles['info-page__primary-content-section-title']}>CALIFORNIA’S SHINE THE LIGHT LAW</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Subject to certain limits under California Civil Code section 1798.83, California residents may ask us to provide them with (i) a list of certain categories of personal data that we have disclosed to third parties for their direct marketing purposes during the immediately preceding calendar year, and (ii) the identity of those third parties. California residents may make one request per calendar year.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Except with respect to the limited exceptions described in the Privacy Policy, we do not share your personal information with any third parties for direct marketing purposes. To make this request, you may contact us at the mail or email address listed in the “Contact Us for More Information” section below. In your request, please state that you are a California resident and provide a current California address for our response. Please allow up to thirty (30) days for a response. We reserve our right not to respond to such requests submitted to addresses other than those specified in the “Contact Us for More Information” section below.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="privacy-rights-act">
                    <div className={styles['info-page__primary-content-section-title']}>CALIFORNIA PRIVACY RIGHTS ACT</div>
                    <div className={`${styles['info-page__primary-content-section-title']} ${utilStyles['scroll-to-section']}`} id="under-cpra">1. California Residents’ Rights. </div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>If you are a California resident, you have certain rights regarding information that is treated as personal information under the CPRA.</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item-title']}`}>The right to know what categories of personal information are being collected or disclosed and the categories of sources of that personal information</li>
                        <div className={styles['info-page__primary-content-section-paragraph']}>You have the right to know what categories of personal information we collect, sell, or disclose about you, as well as the categories of third parties who received your personal information and the categories of sources of that personal information. These categories are described in the “Categories of California Consumers’ personal information Collected or Disclosed” section below. We are also required to provide to you, before or at the point of collection, with a general notice about the categories of personal information collected by us and how we intend to use the collected personal information.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']} />
                        <li className={`{${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item-title']}`}>The right to access collected personal information</li>
                        <div className={styles['info-page__primary-content-section-paragraph']}>You have the right to request that we provide a copy of your personal information that we collected or disclosed in the preceding twelve (12) months. Only you, or someone you legally authorize to act on your behalf, may make a request related to your personal information. You may submit a maximum of two requests in a 12-month period.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']}>You must provide sufficient information to enable us to reasonably verify your identity or that of your authorized agent. We will only use the personal information provided in your request to verify your identity or that of your authorized agent. Please note that we cannot provide any personal information in response to a request if we cannot verify your or your authorized agent’s identity, or your authorized agent’s authority to make the request on your behalf, and/or if we cannot confirm that the collected personal information relates to you.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']}>You are not required to create an account with us before submitting a verifiable request. Please submit your request using the online Data Privacy Request form found at <CustomLink className={`${styles.link} ${styles['link--blue']} ${styles['link--overflow-wrap']}`} linkPath={routes.dataprivacyrequest.path}>https://portal.healthycontributions.com/dataprivacyrequest</CustomLink>. After receiving and verifying your request, we will provide the following information (depending on your request):</div>
                        <ul className={styles['info-page__primary-content-section-list']}>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>The categories of personal information collected about you in the preceding twelve (12) months;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>The sources of that personal information by category;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>The business or commercial purpose(s) for collecting that personal information;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>The categories of third parties with whom we shared that personal information, if applicable;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>The specific pieces of personal information collected about you.</li>
                        </ul>
                        <div className={styles['info-page__primary-content-section-paragraph']}>If we disclosed your personal information for a business purpose, we will provide:</div>
                        <ul className={styles['info-page__primary-content-section-list']}>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>The categories of personal information disclosed for a business purpose in the previous twelve (12) months and the categories of third parties, if applicable, to whom the personal information was disclosed.</li>
                        </ul>
                    </ul>
                    <div className={styles['info-page__primary-content-section-paragraph']}>See the “Methods for Submitting CPRA Requests to Us” section below for information on how you can exercise your right to access collected personal information. For more information, see also the “Response Timing and Format” section below.</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={`{${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item-title']}`}>The right to request deletion of personal information</li>
                        <div className={styles['info-page__primary-content-section-paragraph']}>With certain exceptions, you have the right to request that we and our service providers (where applicable) delete personal information we or our service providers collected or maintained. Once we receive and verify your request, we will delete and instruct any applicable service providers to delete your personal information from our records, unless an exception applies.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']}>We and our service providers may deny a verified deletion request if we must maintain your personal information to:</div>
                        <ul className={styles['info-page__primary-content-section-list']}>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Complete the transaction or provide a good or service requested by you for which we collected the personal information, or otherwise perform a contract between us;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that activity;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Debug to identify and repair errors that impair existing intended functionality of our systems;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Exercise a legal right provided for by law, including exercising or ensuring free speech rights;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Comply with the California Electronic Communications Privacy Act (California Penal Code sections 1546 to 1546.4);</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest if the research adheres to all other applicable ethics and privacy laws; deleting the personal information is likely to make the research impossible or seriously impair it; and you provided informed consent during the initial data collection;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Enable our internal use in a manner that you would reasonably expect based on your relationship with us;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Comply with our legal obligations;</li>
                            <li className={`${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item--disc']}`}>Otherwise use your personal information internally in a lawful manner that is compatible with the context in which you provided the information.</li>
                        </ul>
                        <div className={styles['info-page__primary-content-section-paragraph']}>See the “Methods for Submitting CPRA Requests to Us” section below for information on how you can exercise your right to delete collected personal information.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']} />
                        <li className={`{${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item-title']}`}>The right to be free from discrimination</li>
                        <div className={styles['info-page__primary-content-section-paragraph']}>You have the right to equal service, and we will not discriminate against you if you exercise your rights under the CPRA. However, under certain circumstances, we may offer you special discounts or other incentives for the right to continue to collect or use your personal information. In that event, we will notify you of the relevant program’s material terms and obtain your voluntary opt-in consent to the program.</div>
                    </ul>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="categories-california">
                    <div className={styles['info-page__primary-content-section-title']}>2. Categories of California Consumers’ Personal Information Collected or Disclosed.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>We collect a variety of categories of personal information about California consumers, their household, and/or their devices from both online and offline (such as hard-copy forms) sources. We will not collect additional categories of personal information or use the personal information we collect for materially different, unrelated, or incompatible purposes without providing advance notice.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>In particular, the following types of personal information we may have collected and reason for collection in the last twelve (12) months includes but is not limited to:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your contact information to create an account, such as your first name, last name, phone number, and email address;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your transaction history relating to the use of clubs, or other information you wish to share with us;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Information regarding club usage;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Information provided by you when you participate in a survey or communication to you regarding your use of the Website, or utilizing any of our Healthy Contributions services;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Information provided by you to participate in advertising contests or sweepstakes;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Various account information related to your accounts to allow you to update credit card, bank account, other payment information, or any other information regarding your profile;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>If you are a wellness partner, we may collect detailed information such as your first name, last name, email address, phone number, address;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>If we offer alternative sign-ins, we may collect certain sign on information you have provided to third parties, such as Facebook or Twitter, when you sign on to use our Website through these third parties;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Computer data or files, often called “cookies,” which may be sent by your web browser and stored on an individual computer to allow our server to “recognize” you through the navigation of the Website;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Computer data files, often called “web beacons” that are collected from emails or links on third-party sites that allow our server to evaluate the popularity of those links or the information contained in emails or on third-party sites; and</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your IP address to measure our website traffic and to help provide a more personalized experience.</li>
                    </ul>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Information obtained from your Social Media Accounts, where applicable:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}>You may choose to log into your account with Healthy Contributions on the Website via certain social media networking services, such as Facebook and Twitter (“Social Media Accounts”). By integrating these Social Media Accounts into your account, we can offer you more personalized services, but may also collect certain personal information provided in your Social Media Accounts, such as your profile information, email address, profile picture and friend list. If you do not want us to have access to this information, do not utilize the Social Media Account sign-in feature.</li>
                    </ul>
                    <div className={styles['info-page__primary-content-section-paragraph']}>We may have shared or disclosed for a business purposes the following personal information with the following categories of recipients:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}>Personal Identifiers. Recipients of this information may include affiliates and subsidiaries, website development and (cloud) hosting providers, email platform provider, marketing analytics companies, if applicable, and business partners.</li>
                    </ul>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="methods-submitting">
                    <div className={styles['info-page__primary-content-section-title']}>3. Methods for Submitting CPRA Requests to Us.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>You have the right to submit a verifiable request to us using the following method:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}><span className={styles['info-page__primary-content-section-list-item-title']}>Online Request Form.</span> You may submit a data privacy request by completing the online Data Privacy Request form located here: <CustomLink className={`${styles.link} ${styles['link--blue']} ${styles['link--overflow-wrap']}`} linkPath={routes.dataprivacyrequest.path}>https://portal.healthycontributions.com/dataprivacyrequest</CustomLink>.
                        </li>
                    </ul>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Further, the CPRA requires that businesses recognize browser-based signals as a means for consumers to opt-out of sales and sharing of personal information, and targeted advertising. As such, we recognize browser-based opt-out preference signals such as the Global Privacy Control.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="verification-process">
                    <div className={styles['info-page__primary-content-section-title']}>4. Verification Process.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Once we receive a request to know or to delete, we must verify your identity before we can respond. You may be required to provide the following information for verification purposes and efficiency when applicable:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your first and last name;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your home address;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your e-mail address;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Your telephone number</li>
                    </ul>
                    <div className={styles['info-page__primary-content-section-paragraph']}>If you submit either a request to know specific pieces of personal information collected, or a request to delete sensitive, irreplaceable, or otherwise valuable personal information, you (or your authorized agent, if applicable) will be required to provide a signed declaration affirming your identity (or your authorized agent’s identity, if applicable).</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="authorized-agents">
                    <div className={styles['info-page__primary-content-section-title']}>5. Authorized Agents.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>An authorized agent is either a natural person or a business entity registered with the California Secretary of State that a consumer has authorized to act on his or her behalf. You may use an authorized agent to submit a request to know or request to delete. You may make such a designation by providing the agent with written permission to act on your behalf. We will require the authorized agent provide either (1) a power of attorney or (2) proof of your written permission. In addition to proof of your written permission, we may require you to verify your own identity and directly confirm that you provided the agent permission to submit the request.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="response-triming">
                    <div className={styles['info-page__primary-content-section-title']}>6. Response Timing and Format.</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={`{${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item-title']}`}>Acknowledgement of receipt of request</li>
                        <div className={styles['info-page__primary-content-section-paragraph']}>We will confirm receipt of a request from you within ten (10) days. This confirmation will provide information about how we will process the request, including a description of our verification process and an approximation of when we will send you a substantive response.</div>
                    </ul>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={`{${styles['info-page__primary-content-section-list-item']} ${styles['info-page__primary-content-section-list-item-title']}`}>Substantive response</li>
                        <div className={styles['info-page__primary-content-section-paragraph']}>We will respond to a verifiable request from you within forty-five (45) days. If we cannot respond in that time, we will notify you in writing that we need an additional forty-five (45) days and explain the reason for the needed additional time. If you have an account with us, we will respond to that account. If you do not have an account with us, we will deliver the written response by mail or e-mail, at your option.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']}>Any disclosures we provide will cover only the 12-month period preceding our receipt of your verifiable request. If we cannot comply with your request, we will explain why. If you have requested to know specific personal information we collected, we will provide that information to you.</div>
                        <div className={styles['info-page__primary-content-section-paragraph']}>We do not charge a fee to process or respond to a verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why and provide you with a cost estimate before completing the request.</div>
                    </ul>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="sale-sharing">
                    <div className={styles['info-page__primary-content-section-title']}>7. Sale or Sharing of Your Personal Information.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>California residents have the right to opt-out of the sale or sharing of their Personal Information. California law defines “sale” very broadly and so, for example, placing certain types of cookies on our website may fall within scope. We have implemented technical controls to limit the sale or sharing of personal information with other entities. However, we may share information about you with our affiliates in accordance with applicable requirements. Under California, this could be considered a “sale” and so we want to share this information with you. Even though we do not sell your personal information as one generally thinks of the word “sale”, you have the right to opt-out of the sale or sharing of your personal information.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="financial-incentive-offers">
                    <div className={styles['info-page__primary-content-section-title']}>8. Financial Incentive Offers.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>At this time, we do not offer any financial incentives or price or service differences that relate to our collection, retention, or use of your Personal Information. However, if this changes, we will provide a written description of the material terms of the financial incentive or price or service difference to you in a written Notice of Financial Incentive and you will only be enrolled as a participant if you affirmatively opt-in to the program. You may revoke your opt-in consent at any time. The Notice of Financial Incentive will explain the processes for opting-in to participate and revoking your consent (opting-out).</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="metrics-cpra">
                    <div className={styles['info-page__primary-content-section-title']}>9. Metrics Regarding CPRA Requests and Responses During the Previous Calendar Year.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>At the end of each calendar year, we will report as applicable regarding the number of requests to know or requests to delete that we received, complied with in whole or in part, or denied, and the median length of our response time.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="years-age">
                    <div className={styles['info-page__primary-content-section-title']}>10. The Rights of California Consumers Who are Less than 16 Years of Age.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Under California law, consumers who are less than 16 years of age must provide affirmative authorization, or a parent or guardian of a consumer less than 13 must provide affirmative authorization, before a business may sell their Personal information. This is referred to as the “right to opt-in.” We do not have actual knowledge that we sell the Personal Information of California consumers who are less than 16 years of age.</div>
                </section>
                <section className={`${styles['info-page__primary-content-section']} ${utilStyles['scroll-to-section']}`} id="contact-us">
                    <div className={styles['info-page__primary-content-section-title']}>11. Contact Us for More Information.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>California consumers who have questions or concerns about our privacy policies and practices may contact us via:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}>Email: <span className={`${styles.link} ${styles['link--blue']}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</span></li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Mail: <br />{HEALTHY_CONTRIBUTIONS_LEGAL_NAME}<br />Attention: Data Protection Officer<br />111 Weir Drive<br />Woodbury, Minnesota 55125 U.S.A.</li>
                    </ul>
                    <div className={styles['info-page__primary-content-section-paragraph']}>When contacting us, please include in the communication or have available the information listed under the “Verification Process” section above. If using an authorized agent, please include in the communication or have available proof of authorization.</div>
                </section>
            </div>
            <div className={styles['info-page__secondary-content']}>
                <div className={styles['info-page__secondary-content-title']}>In the following Privacy Notice, we provide you with information regarding:</div>
                <div className={styles['info-page__secondary-content-description']}>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#shine-light-law">California’s Shine the Light Law</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#privacy-rights-act">California Privacy Rights Act</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#under-cpra">1. California Residents’ Rights Under the CPRA</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#categories-california">2. Categories of California Consumers’ Personal Information Collected or Disclosed</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#methods-submitting">3. Methods for Submitting CPRA Requests to Us and Global Privacy Controls</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#verification-process">4. Verification Process</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#authorized-agents">5. Authorized Agents</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#response-triming">6. Response Timing and Format</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#sale-sharing">7. Sale or Sharing of Your Personal Information</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#financial-incentive-offers">8. Financial Incentive Offers</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#metrics-cpra">9. Metrics Regarding CPRA Requests and Responses During the Previous Calendar Year</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#years-age">10. The Rights of California Consumers Who are Less than 16 Years of Age</a></div>
                    <div className={styles['info-page__secondary-content-paragraph']}><a className={`${styles.link} ${styles['link--blue']}`} href="#contact-us">11. Contact Us for More Information</a></div>
                </div>
            </div>
        </div>
    </div>;
}

export default CaliforniaPrivacyPolicy20230110;