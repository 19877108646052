import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import selectors from './headerSelectors';
import actions from '../../features/App/actions';
import { API_PATHS } from '../../http/configs';
import { authUserType } from '../../propTypes/customTypes';
import routes from '../../routing/routes';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import Image from '../Image/Image';
import { setIsLoginPopupOpen } from '../Login/loginActions';
import { isMobileSize } from '../../utilities/windowSize';
import loginResources from '../Login/loginResources';
import roles from '../../enums/roles';
import AdminHeaderNavList from './AdminHeaderNavList';
import GuestHeaderNavList from './GuestHeaderNavList';

function HeaderInternal({ selectedMemberGroup, me, changeSelectedMemberGroup, setIsLoginPopupOpen, abbreviatedName }) {
    const [isNavigationMenuExpanded, setIsNavigationMenuExpanded] = useState(false);
    const [isAccountMenuExpanded, setIsAccountMenuExpanded] = useState(false);
    const isMobile = isMobileSize();

    useEffect(() => {
        if (!isMobile) {
            setIsNavigationMenuExpanded(false);
        }
    }, [isMobile]);

    const headerProfileLinkRef = useRef();

    const handleWindowClick = function (e) {
        if (headerProfileLinkRef.current && !headerProfileLinkRef.current.contains(e.target)) {
            setIsAccountMenuExpanded(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleWindowClick);
        return () => { window.removeEventListener('click', handleWindowClick); };
    }, []);

    const showMenuButton = !me || (me.role !== roles.member && me.role !== roles.partnerManager);
    const renderHeader = () => {
        if (!me) {
            return <GuestHeaderNavList isMobile={isMobile} />;
        }
        switch (me.role) {
            case roles.member:
            case roles.partnerManager:
                return <Fragment />;
            case roles.systemAdmin:
                return <AdminHeaderNavList />;
            default:
                return <GuestHeaderNavList isMobile={isMobile} />;
        }
    };

    const memberGroupsClickHandler = (group) => {
        setIsAccountMenuExpanded(false);
        changeSelectedMemberGroup(group);
    };

    const logoutClickHandler = (e) => {
        e.preventDefault();
        setIsAccountMenuExpanded(false);
        window.location.href = API_PATHS.logout;
    };

    return (
        <header>
            <div className="header">
                <div className="header__content d-flex justify-content-between">
                    {showMenuButton ?
                        <button className="new-link header__open-menu-button" onClick={() => setIsNavigationMenuExpanded(true)}>
                            <MaterialIcon className="new-link__icon" icon="menu" />
                        </button>
                        : <></>
                    }
                    {!me || !me.role || me.role === roles.guest ?
                        <NavLink to={routes.home.path} className="header__logo">
                            <span className="header__logo-wrapper">
                                <Image image="logoGreen" imageHover="logoGreenHover"
                                    alt="Healthy Contributions Homepage"
                                />
                            </span>
                        </NavLink>
                        : <span className="header__logo-wrapper">
                            <Image image="logoGreen" alt="Healthy Contributions Homepage" />
                        </span>
                    }
                    {
                        !isMobile || (isMobile && isNavigationMenuExpanded) ?
                            <nav className="header__nav" aria-label="Public Pages">
                                <button className="new-link header__close-menu-link" onClick={() => setIsNavigationMenuExpanded(false)}>
                                    <MaterialIcon className="new-link__icon" icon="close" />
                                </button>
                                <div className="header__nav-list-container">
                                    {renderHeader()}
                                </div>
                            </nav>
                            : <></>
                    }
                    <div className="header__actions">
                        {me && me.role ?
                            <>
                                <div className="header__profile-link" ref={headerProfileLinkRef}>
                                    <div tabIndex={0} className="header__profile-icon"
                                        onClick={() => setIsAccountMenuExpanded(!isAccountMenuExpanded)}
                                    >{abbreviatedName}</div>
                                    {
                                        isAccountMenuExpanded
                                            ? <div className="header__account-menu header__account-menu--visible">
                                                <div className="header__close-account-menu">
                                                    <button className="new-link header__close-account-menu-link"
                                                        onClick={() => setIsAccountMenuExpanded(false)}
                                                    >
                                                        <MaterialIcon className="new-link__icon" icon="close" />
                                                    </button>
                                                </div>
                                                <div className="header__account-fullname">
                                                    <div className="header__profile-icon">{abbreviatedName}</div>
                                                    {selectedMemberGroup.fullName}
                                                </div>
                                                {
                                                    me.memberGroups && me.memberGroups.length > 1
                                                        ? <div className="header__accounts-list">
                                                            {
                                                                me.memberGroups.map((group, i) =>
                                                                    <a href={`#member-group${i}`} className="new-link header__accounts-list-item"
                                                                        onClick={() => memberGroupsClickHandler(group)} key={i}
                                                                    >
                                                                        {group.fullName}
                                                                    </a>
                                                                )
                                                            }
                                                        </div>
                                                        : <></>
                                                }
                                                <div className="header__account-logout">
                                                    <a className="new-link" href={API_PATHS.logout}
                                                        onClick={logoutClickHandler}
                                                    >
                                                        <MaterialIcon className="new-link__icon" icon="logout" />
                                                        <span className="header__link-label">logout</span>
                                                    </a>
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                </div>
                            </>
                            :
                            <button id={loginResources.loginButtonId} className="new-link" onClick={() => setIsLoginPopupOpen(true)}>
                                <MaterialIcon className="new-link__icon header__link-icon header__link-icon--login" icon="login" />
                                <span className="header__link-label header__link-label--login">login</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

HeaderInternal.propTypes = {
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    me: authUserType,
    changeSelectedMemberGroup: PropTypes.func,
    setIsLoginPopupOpen: PropTypes.func,
    abbreviatedName: PropTypes.string
};

const mapDispatchToProps = {
    setIsLoginPopupOpen,
    ...actions
};

export default connect(selectors, mapDispatchToProps)(HeaderInternal);
