import React from 'react';
import ClubLocatorSection from '../../../../components/ClubLocatorSection/ClubLocatorSection';
import ForMembersSection from './ForMembersSection';
import PropTypes from 'prop-types';

function ForMembers({ history }) {
    return <ForMembersSection
        history={history}
        clubLocatorSection={<ClubLocatorSection header={<>Find <span className="green">your club</span></>} history={history} />}
           />;
};

ForMembers.propTypes = {
    history: PropTypes.object.isRequired,
};

export default ForMembers;