import React from 'react';
import PropTypes from 'prop-types';
import styles from './_benefits-section.module.scss';
import TextSection from '../TextSection/TextSection';

function BenefitsSection({ header, description, children }) {
    return <div className={styles['benefits-section']}>
        <div className={styles['benefits-section__header']}>
            <TextSection title={header}
                text={description}
            />
        </div>
        <ul className={styles['benefits-section__items']}>
            {children}
        </ul>
    </div>;
}

BenefitsSection.propTypes = {
    header: PropTypes.oneOfType( [ PropTypes.string, PropTypes.node ] ).isRequired,
    description: PropTypes.oneOfType( [ PropTypes.string, PropTypes.node ] ).isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default BenefitsSection;