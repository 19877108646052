import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import images from './Images.js';
import styles from './_image.module.scss';

function Image({ image, imageHover, alt, className, onClick, disabled, ...props }) {
    const [hovered, setHovered] = useState(false);
    const handleClick = (event) => {
        onClick && !disabled && onClick(event);
    };
    if (!imageHover) {
        imageHover = image;
    }
    return (
        <img
            className={classNames(
                styles.image,
                { [className]: className },
                { disabled },
                { [styles['image--clickable']]: onClick !== undefined }
            )}
            alt={alt || image}
            src={hovered ? images[imageHover] : images[image]}
            onClick={handleClick}
            onMouseOut={() => setHovered(false)}
            onMouseOver={() => setHovered(true)}
            {...props}
        />
    );
}

Image.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default Image;
