import React from 'react';
import routes from '../../../routing/routes';
import { NavLink } from 'react-router-dom';
import allyant from '../allyant.png';
import { API_PATHS } from '../../../http/configs';
import footerResources from '../footerResources';
import './footerInternal.scss';
import Image from '../../Image/Image';
import { HEALTHY_CONTRIBUTIONS_LEGAL_NAME } from '../../../constants/companyNames';

function FooterInternal() {
    return (
        <footer id="footer" className="hidden-print">
            <div className="footer-internal">
                <div className="footer__menu-container not-show-desktop">
                    <ul className="footer__menu-list" role="presentation">
                        <li>
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.privacynotice.path}
                            >
                                {footerResources.linkNamePrivacyNotice}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.consumerhealthprivacynotice.path}
                            >
                                {footerResources.linkNameConsumerHealthPrivacyNotice}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.termsofuse.path}
                            >
                                {footerResources.linkNameTermsOfUse}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.accessibilityAndMaintenance.path}
                            >
                                {footerResources.linkNameAccessibilityAndMaintenance}
                            </NavLink>
                        </li>
                        <li>
                            <a href={API_PATHS.homeSiteMap}
                                className="footer-menu-item__submenu-link"
                            >
                                {footerResources.linkSiteMap}
                            </a>
                        </li>
                    </ul>
                    <div className="d-block" />
                </div >
                <div className="footer__allyant-container col-12 not-show-desktop">
                    <div className="footer__text">
                        {footerResources.headerAllyant}
                    </div>
                    <a href={API_PATHS.allyant} className="footer__img">
                        <img src={allyant} alt="Reviewed by Allyant for accessibility" />
                    </a>
                </div>
                <div className="footer__privacy-container col-12 col-md-4">
                    <div className="footer__logo-container">
                        <a href={routes.home.path} className="footer__logo-image-wrapper">
                            <Image image="logoWhite" alt="Healthy Contributions" />
                        </a>
                        <div className="footer__copyright-container footer__copyright-text">
                            Copyright © {new Date().getFullYear()} {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}. All rights reserved.
                        </div>
                        <div className="footer__cookie-container" dangerouslySetInnerHTML={{ __html: '<a id="ot-sdk-link" className="ot-sdk-show-settings" href="#" alt="Cookie Settings" onclick="window.OneTrust.ToggleInfoDisplay()">Cookie Settings</a>' }} />
                    </div>
                </div>
                <div className="footer__menu-container show-only-desktop">
                    <ul className="footer__menu-list" role="presentation">
                        <li className="separator">
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.privacynotice.path}
                            >
                                {footerResources.linkNamePrivacyNotice}
                            </NavLink>
                        </li>
                        <li className="separator">
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.consumerhealthprivacynotice.path}
                            >
                                {footerResources.linkNameConsumerHealthPrivacyNotice}
                            </NavLink>
                        </li>
                        <li className="separator">
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.termsofuse.path}
                            >
                                {footerResources.linkNameTermsOfUse}
                            </NavLink>
                        </li>
                        <li className="separator">
                            <NavLink
                                className="footer-menu-item__submenu-link"
                                to={routes.accessibilityAndMaintenance.path}
                            >
                                {footerResources.linkNameAccessibilityAndMaintenance}
                            </NavLink>
                        </li>
                        <li>
                            <a href={API_PATHS.homeSiteMap}
                                className="footer-menu-item__submenu-link"
                            >
                                {footerResources.linkSiteMap}
                            </a>
                        </li>
                    </ul>
                </div >
                <div className="footer__allyant-container col-3 show-only-desktop">
                    <div className="footer__text">
                        {footerResources.headerAllyant}
                    </div>
                    <a href={API_PATHS.allyant} className="footer__img">
                        <img src={allyant} alt="Reviewed by Allyant for accessibility" />
                    </a>
                </div>
            </div >
        </footer>
    );
}

export default FooterInternal;
