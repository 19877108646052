import * as types from './groupMembershipsTypes';
import * as groupMembershipsService from './groupMembershipsService';
import * as loadingActions from '../../Loading/loadingActions';
import * as employeeManagementService from '../EmployeeManagement/employeeManagementService';
import facilityFeesBillingTypes, { facilityFeesBillingTypesFullNamesMap } from '../../../enums/facilityFeesBillingTypes';
import installmentTypes, { installmentTypesFullNamesMap } from '../../../enums/installmentTypes';
import reimbursementTypes, { reimbursementTypesFullNamesMap } from '../../../enums/reimbursementTypes';
import groupMembershipsInfoResources from './GroupMembershipsInfoForm/groupMembershipsInfoResources';

const setGroupMembership = (groupMembership) => {
    return { type: types.SET_GROUP_MEMBERSHIP, groupMembership };
};
const setGroupMembershipToAddAction = (groupMembershipToAdd) => {
    return { type: types.SET_GROUP_MEMBERSHIP_TO_ADD, groupMembershipToAdd };
};
const getDefaultGroupMembership = (partnerId) => {
    return {
        partnerId,
        [groupMembershipsInfoResources.fieldProgramPaymentType]: undefined,
        [groupMembershipsInfoResources.fieldAdminFee]: undefined,
        [groupMembershipsInfoResources.fieldProgramFee]: undefined,
        [groupMembershipsInfoResources.fieldNumberOfMemberships]: undefined,
        [groupMembershipsInfoResources.fieldDuesForOneMemberPerMonthPaidToFacility]: undefined,
        [groupMembershipsInfoResources.fieldDuesAmount]: undefined,
        [groupMembershipsInfoResources.fieldMemberReimbursementType]: undefined,
        [groupMembershipsInfoResources.fieldReimbursementAmount]: undefined,
        [groupMembershipsInfoResources.fieldNumberOfVisitsToReimburse]: undefined,
        [groupMembershipsInfoResources.fieldPaymentForActivatedMembersOnly]: false,
        facilityFeesBillingTypeID: facilityFeesBillingTypes.directFromFacility, //Direct From Bank Account
        facilityFeesBillingTypeName: facilityFeesBillingTypesFullNamesMap[facilityFeesBillingTypes.directFromFacility],
        installmentTypeID: installmentTypes.directFromPartner, //Direct From Partner
        installmentTypeIName: installmentTypesFullNamesMap[installmentTypes.directFromPartner],
        reimbursementTypeID: reimbursementTypes.toFacility, //Direct To Bank Account
        reimbursementTypeName: reimbursementTypesFullNamesMap[reimbursementTypes.toFacility]
    };
};
export function resetGroupMembershipToAdd() {
    return function (dispatch) {
        dispatch(setGroupMembershipToAddAction(undefined));
    };
}
export function resetGroupMembership(partnerId) {
    return function (dispatch) {
        dispatch(setGroupMembership(getDefaultGroupMembership(partnerId)));
        dispatch(setGroupMembershipToAddAction(undefined));
        dispatch(setHasEnrolledOrInvitedMembers(false));
    };
}

export function setGroupMembershipToAdd(groupMembership) {
    return function (dispatch) {
        dispatch(setGroupMembershipToAddAction(groupMembership));
    };
}

export function getGroupMembership(partnerId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_GROUP_MEMBERSHIP));
        return groupMembershipsService
            .getGroupMembership(partnerId)
            .then((groupMembership) => {
                dispatch(setGroupMembership(groupMembership));
            })
            .catch((errorResponse) => {
                if (errorResponse.status === 404) {
                    dispatch(resetGroupMembership(partnerId));
                } else {
                    throw errorResponse;
                }
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_GROUP_MEMBERSHIP));
                dispatch(setGroupMembershipToAddAction(undefined));
            });
    };
}

export function updateGroupMembership(groupMembership) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.UPDATE_GROUP_MEMBERSHIP));
        return groupMembershipsService
            .updateGroupMembership(groupMembership)
            .then((result) => {
                if (result.success) {
                    dispatch(setGroupMembership(result.data));
                }
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.UPDATE_GROUP_MEMBERSHIP));
                dispatch(setGroupMembershipToAddAction(undefined));
            });
    };
}

export function addGroupMembership(groupMembership) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.ADD_GROUP_MEMBERSHIP));
        return groupMembershipsService
            .addGroupMembership(groupMembership)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.ADD_GROUP_MEMBERSHIP));
                dispatch(setGroupMembershipToAddAction(undefined));
            });
    };
}

export function getMembersCount(values) {
    return function () {
        return employeeManagementService.getMembersCount(values.get('partnerId'));
    };
}

const setHasEnrolledOrInvitedMembers = (hasEnrolledOrInvitedMembers) => {
    return { type: types.SET_GROUP_MEMBERSHIP_HAS_ENROLLED_OR_INVITED_MEMBERS, hasEnrolledOrInvitedMembers };
};

export function getHasEnrolledOrInvitedMembers(partnerID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_GROUP_MEMBERSHIP_HAS_ENROLLED_OR_INVITED_MEMBERS));
        groupMembershipsService.getHasEnrolledOrInvitedMembers(partnerID)
            .then((response) => {
                dispatch(setHasEnrolledOrInvitedMembers(response.data));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_GROUP_MEMBERSHIP_HAS_ENROLLED_OR_INVITED_MEMBERS));
            });
    };
}
