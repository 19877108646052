import React from 'react';
import PropTypes from 'prop-types';
import QuotesIcon from '../../assets/icons/quotes.svg';
import styles from  './_quotes-section.module.scss';

function QuotesSection({ header, description, text, signature }) {
    return (
        <div className={styles['quotes-section']}>
            <div className={styles['quotes-section__description-wrapper']}>
                <h2 className={styles['quotes-section__header']}>{header}</h2>
                <div className={styles['quotes-section__description']}>{description}</div>
            </div>
            <div className={styles['quotes-section__item-wrapper']}>
                <div className={styles['quotes-section__item']}>
                    <div className={styles['quotes-section__quotes']}>
                        <img src={QuotesIcon} alt="" aria-hidden="true" />
                    </div>
                    <blockquote className={styles['quotes-section__item-text']}>
                        {text}
                    </blockquote>
                    <div className={styles['quotes-section__item-signature']}>
                        {signature}
                    </div>
                </div>
            </div>
        </div>
    );
}

QuotesSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired
};

export default QuotesSection;