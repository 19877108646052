import { createSelector } from 'reselect';
import roles from '../../enums/roles';
import routes from '../../routing/routes';

const getMe = state => state.app.get('me');
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');
const getAbreviatedName = createSelector([getSelectedMemberGroup],
    selectedMemberGroup => (selectedMemberGroup.abbreviatedName ? selectedMemberGroup.abbreviatedName.replace(' ', '') : ''));

const getPortalDashboardLink = createSelector(
    [getMe],
    (me) => {
        if (!me) {
            return;
        }
        switch (me.role) {
            case roles.member:
                return routes.memberArea.path;
            case roles.partnerManager:
                return routes.partnerManagerArea.path;
            default:
                return null;
        }
    }
);

export default createSelector(
    [getMe, getSelectedMemberGroup, getPortalDashboardLink, getAbreviatedName],
    (me, selectedMemberGroup, portalDashboardLink, abbreviatedName) => ({
        me, selectedMemberGroup, portalDashboardLink, abbreviatedName
    })
);