import React from 'react';
import PropTypes from 'prop-types';
import styles from './_metrics-section.module.scss';
import TextSection from '../TextSection/TextSection';

function MetricsSection({ header, description, children, columns }) {
    return <div className={styles['metrics-section']}>
        <TextSection title={header} text={description}
            theme="centered"
        />
        <ul className={`${styles['metrics-section__items']} ${styles[`metrics-section__items--columns-${columns}`]} ul-no-style`}>
            {children}
        </ul>
    </div>;
}

MetricsSection.defaultProps = {
    columns: 3
};

MetricsSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    columns: PropTypes.number
};

export default MetricsSection;