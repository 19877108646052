import React from 'react';
import PropTypes from 'prop-types';
import styles from './_call-to-action.module.scss';

function CallToAction(props) {
    return (
        <div className={`${styles['call-to-action']} ${styles[`call-to-action call-to-action--${props.theme}`]}`}>
            {props.children}
        </div>
    );
}

CallToAction.defaultProps = {
    theme: 'right-image'
};

CallToAction.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    theme: PropTypes.string
};

export default CallToAction;
