import { matchPath } from 'react-router-dom';

export const authorizeRoutes = (me, routesToRender) => {
    const authorizedRoutes = routesToRender.filter(route =>
        hasRole(route.authorize, me ? me.role : undefined)
    );
    authorizedRoutes.forEach(route => authorizeChildRoutes(me, route));

    return authorizedRoutes;
};

function authorizeChildRoutes(me, route) {
    if (route.routes && route.routes.length > 0) {
        route.routes.forEach((subroute) => {
            subroute.authorize = !!subroute.authorize ? subroute.authorize : route.authorize;
            authorizeChildRoutes(me, subroute);
        });
        route.routes = authorizeRoutes(me, route.routes);
    }
}

const hasRole = (requiredRoles, userRole) => !requiredRoles || requiredRoles.includes(userRole);

export function checkIfPathMatchesAnyInCollection(currentPath, pathsCollection) {
    return pathsCollection.some(path => matchPath(currentPath, { path, exact: true }));
}