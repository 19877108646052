import React from 'react';
import PropTypes from 'prop-types';
import { API_PATHS } from '../../http/configs';
import selectors from './headerSelectors';
import { connect } from 'react-redux';
import { authUserType } from '../../propTypes/customTypes';
import routes from '../../routing/routes';
import CustomLink from '../controls/CustomLink';

function GuestHeaderNavList({ isMobile, me, portalDashboardLink }) {

    return (
        <ul className="header__nav-list">
            {me && me.role && <li className="header__nav-item">
                {portalDashboardLink ?
                    <CustomLink
                        className="new-link"
                        linkPath={portalDashboardLink}
                        activeClassName="header__nav-item--active"
                    >
                        dashboard
                    </CustomLink>
                    :
                    <a href={API_PATHS.HCDashboard}
                        className="new-link"
                    >
                        dashboard
                    </a>
                }
            </li>
            }
            {isMobile && <li className="header__nav-item">
                <CustomLink
                    className="new-link"
                    linkPath={routes.home.path}
                    activeClassName="header__nav-item--active"
                >
                    home page
                </CustomLink>
            </li>}
            <li className="header__nav-item">
                <CustomLink
                    className="new-link"
                    linkPath={routes.forFacilities.path}
                    activeClassName="header__nav-item--active"
                >
                    for facilities
                </CustomLink>
            </li>
            <li className="header__nav-item">
                <CustomLink
                    className="new-link"
                    linkPath={routes.forEmployers.path}
                    activeClassName="header__nav-item--active"
                >
                    for employers
                </CustomLink>
            </li>
            <li className="header__nav-item">
                <CustomLink
                    className="new-link"
                    linkPath={routes.forInsurers.path}
                    activeClassName="header__nav-item--active"
                >
                    for insurers
                </CustomLink>
            </li>
            <li className="header__nav-item">
                <CustomLink
                    className="new-link"
                    linkPath={routes.forMembers.path}
                    activeClassName="header__nav-item--active"
                >
                    for members
                </CustomLink>
            </li>
            <li className="header__nav-item">
                <CustomLink
                    className="new-link"
                    linkPath={routes.aboutUs.path}
                    activeClassName="header__nav-item--active"
                >
                    about us
                </CustomLink>
            </li>
        </ul>
    );
}

GuestHeaderNavList.propTypes = {
    me: authUserType,
    isMobile: PropTypes.bool.isRequired,
    portalDashboardLink: PropTypes.string

};
export default connect(selectors, null)(GuestHeaderNavList);