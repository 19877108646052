import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './rootReducer';
import services from '../http/services';
import { isServer } from '../utilities/next';

export const history = isServer() ? null : createBrowserHistory();

export default function configureStore(initialState) {
    const middlewares = [thunk.withExtraArgument(services)];
    if (history) {
        middlewares.push(routerMiddleware(history));
    }

    return createStore(
        rootReducer(history),
        initialState,
        composeWithDevTools(
            applyMiddleware(...middlewares)
        )
    );
};