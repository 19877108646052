import React from 'react';
import { HEALTHY_CONTRIBUTIONS_LEGAL_NAME } from '../../../../constants/companyNames';
import { HEALTHY_CONTRIBUTIONS_INFO } from '../../../../constants/emails';
import { AMERICAN_ARBITRATION_ASSOCIATION } from '../../../../constants/websites';
import { API_PATHS } from '../../../../http/configs';
import styles from '../../../../styles/_info-page.module.scss';
import { PRIVACY_NOTICE } from '../../../../constants/legal';

function TermsOfUse() {
    return <div className={styles['info-page']}>
        <h1 className={styles['info-page__title']}>Terms of Use</h1>
        <div className={styles['info-page__subtitle']}>Effective Date: January 12, 2023</div>
        <div className={styles['info-page__content']}>
            <div className={styles['info-page__primary-content']}>
                <section className={styles['info-page__primary-content-section']}>
                    <div className={styles['info-page__primary-content-section-paragraph']}>THESE TERMS OF USE CONTAIN A DISPUTE RESOLUTION PROVISION THAT REQUIRES INDIVIDUAL ARBITRATION AND YOU ARE NOT ALLOWED TO BRING YOUR CLAIMS IN COURT, OTHER THAN ON INDIVIDUAL BASIS IN SMALL CLAIMS COURT. PLEASE SEE SECTION “DISPUTE RESOLUTION”.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Please read the following Terms of Use (“Terms of Use”) carefully as they constitute a binding agreement between you and {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}, a Delaware Limited Liability Company (“Healthy Contributions,” “we,” “us” and “our”).</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>These Terms of Use are entered into by and between you and us and govern your use of the website on which these Terms of Use appear, including any content, functionality, products and services offered therein (collectively, the “Site”).</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>These Terms of Use apply to your access to and use of this Site and do not alter in any way the terms and conditions of any other agreement you may have with us. If you breach any of these terms and conditions, your authorization to use this Site automatically terminates and you must immediately discontinue use of this Site.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>1. Acceptance of these Terms of Use.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>By using the Site, you accept these Terms of Use and agree to be bound by them. By using the Site, you represent that you are capable of entering into a binding agreement, and that you agree to be bound by these Terms of Use. Use of the Site is strictly voluntary. IF YOU DO NOT AGREE TO THESE TERMS OF USE AND OUR {PRIVACY_NOTICE.toUpperCase()}, YOU MUST IMMEDIATELY LEAVE THE SITE AND MAY NOT USE THE SITE.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>To access and use certain services available through the Site, you may be required to have a user account with us and you must agree to be bound by any additional applicable terms and conditions contained there.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>2. Collection of Visitor Information on the Site.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Any information collected at this Site and the purposes for which we may use this information are set forth in our <a className={`${styles.link} ${styles['link--blue']}`} href={API_PATHS.privacyNotice}> {PRIVACY_NOTICE}.</a> The terms of our {PRIVACY_NOTICE} are incorporated herein by this reference. Please carefully review the information contained in our {PRIVACY_NOTICE}. By using the Site, you agree to waive and release us from any claim or liability in connection with the collection, use, or disclosure of information that is consistent with the terms of our {PRIVACY_NOTICE}.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>3. Changes to these Terms of Use.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>We may change these Terms of Use without notice to you. Your access or use of the Site following the date on which changes to these Terms of Use or the {PRIVACY_NOTICE} are published in this location shall constitute your acceptance of all such changes. You expressly agree that we shall have no obligation to provide you with any notice of any changes, and you hereby expressly waive any right you may have to receive notice of any changes to these Terms of Use or the {PRIVACY_NOTICE}.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>4. Copyright.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>All software, technology, and content included in the Site or used in the operation of the Site, is our owned or licensed property, or our software, technology, and content providers' property, and is protected by U.S. and international copyright laws. In addition, the compilation (meaning the collection, arrangement, and assembly) of all content in the Site is our exclusive property and is protected by U.S. and international copyright laws. Subject to your compliance with these Terms of Use, we grant you a limited, non- exclusive, non-sublicensable, revocable, non-transferable right to access and use the content, information, and functionality made available on the Site, for your personal and noncommercial use of the Site. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content, software, and technology on the Site is strictly prohibited without our written permission.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>5. Trademarks.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>The trademarks, service marks, trade dress, and “look and feel” (the “Trademarks”) used and displayed in the Site are registered and unregistered Trademarks of us and others. Nothing in the Site shall be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark displayed in the Site, without the prior written permission of the Trademark owner. Other product and company names mentioned in the Site may be the Trademarks of their respective owners.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>6. Other Intellectual Property.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>All other intellectual property rights related to the software, technology, and content included in the Site or used in the operation of the Site, including without limitation, patents, trade secrets, trade dress, publicity rights, character rights, titles, and artistic and moral rights are our owned or licensed property. Any use of these rights without our prior written permission is strictly prohibited.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>7. Reserved Rights.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>We or our licensors own and reserve all right, title, and interest in and to the Site. These Terms of Use do not grant you any rights in or to the Site or any of its components except for the limited rights to use the Site expressly granted by these Terms of Use.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>8. Termination.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Your license to the Site and our obligations under these Terms of Use will automatically terminate if you fail to comply with any provision of these Terms of Use or if the reason you were granted access to the Site terminates. No notice will be required from us to effectuate such termination. Upon termination of these Terms of Use, all of your rights to use the Site shall terminate immediately and we may delete or remove your account and User Content.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>9. Restrictions.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>You may not: (i) reproduce, modify, prepare derivative works based upon, translate, tamper with, distribute, sublicense, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Site except as expressly permitted by us; (ii) reverse engineer, disassemble, or decompile the Site or apply any other process or procedure to derive the source code of any software included in the Site; (iii) use the Site in a manner that interferes with other users' use of the Site; (iv) link to, mirror or frame any portion of the Site; or (v) attempt to gain unauthorized access to or impair any aspect of the Site or its related systems or networks.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>10. Additional Prohibited Activities and Visitor Obligations.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>As a visitor to or user of the Site you shall not:</div>
                    <ul className={styles['info-page__primary-content-section-list']}>
                        <li className={styles['info-page__primary-content-section-list-item']}>Violate any applicable law, rule, or regulation;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Upload, post, use, transmit, or otherwise make available, including by e-mail, (each, a “Post”) any content or information that is false or inaccurate;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Post any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, or content that could otherwise be considered to be objectionable;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Post any publicly available content that is subject to any disclosure restrictions;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Post any materials, items, information or content that infringes or otherwise violates any patent, copyright, trademark, or any other intellectual property rights;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Post any unsolicited or unauthorized advertising or promotional materials, including without limitation, junk mail, spam, “chain letters, “pyramid schemes, or any other form of solicitation;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Post any material that contains software viruses or any other corrupt computer code, files or programs that impair the use of any computer software or hardware or telecommunications equipment;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Impersonate or use the identity of another person or organization, or falsely state or otherwise misrepresent your affiliation with a person or organization;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Engage in any activities or manipulate identifying material to misrepresent the origin of content;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Use the Site for any activity that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, or information or content that could otherwise be considered to be objectionable;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Interfere with or otherwise limit the use of the Site by other users;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Collect, compile, or store personal information about other users of the Site;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Disrupt or interfere with the security of, operation of, or otherwise cause harm to, the Site or the systems resources; accounts; usernames and passwords; servers; or networks connected to or accessible through this Site or any affiliated or linked sites;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Modify, reverse engineer, disassemble, decompile, translate, create derivative works from, or otherwise alter the Site;</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Use the Site in a manner that could cause us to violate any law, rule or regulation; or</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Use any robot, spider, scripts, other automatic device, or manual process to “screen scrape,” index, monitor, “mine,” or copy the web pages on the Site or the content contained therein without our prior, express, and written permission; or</li>
                        <li className={styles['info-page__primary-content-section-list-item']}>Use the Site for your own commercial purposes.</li>
                    </ul>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>11. User Content; Monitoring Activity.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>You agree that you are solely responsible for the Post of any content that you make available on or send through this Site (“User Content”). Although we have no obligation to screen, edit or monitor any of the User Content or user conduct on this Site, we reserve the right, and have absolute discretion, to remove, screen or edit any User Content and to monitor any user conduct on this Site at any time and for any reason without notice. By the Post of User Content to this Site, you represent and warrant that (a) the User Content is non-confidential; (b) you have all necessary rights to Post the User Content to this Site and to grant us the rights you grant in these Terms of Use; (c) the User Content is accurate and not misleading or harmful in any manner; (d) the User Content, and your use and the Post thereof in connection with this Site, does not and will not violate these Terms of Use or any applicable local, state, national or international statute, regulation, or law.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>12. Your Account.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>You agree to maintain accurate, complete, and up-to-date information in your user account on the Site. If you have a user account on this Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password. We reserve the right, without notice and in our sole discretion, to refuse or restrict access or service, terminate accounts, or remove or edit content. If we, in our sole reasonable determination, believe that you have violated any of these Terms of Use, we may suspend or terminate your account. YOU WILL NOT SHARE YOUR USERNAME OR PASSWORD FOR ACCESSING THE SITE WITH ANY OTHER INDIVIDUAL OR THIRD PARTY.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>13. Accuracy.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Information on the Site may contain typographical errors, inaccuracies, or omissions. We reserve the right, but have no obligation, to correct any errors, inaccuracies, or omissions and to discontinue, change or update information or products or services at any time without notice to you.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>14. Disclaimer of Warranties and Limitation of Liability.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>THE SITE AND ALL CONTENT, PRODUCTS, DATA, MATERIALS, AND SERVICES AVAILABLE THROUGH THE SITE ARE PROVIDED ON AN “AS IS, WITH ALL DEFECTS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, SATISFACTORY QUALITY, ACCURACY OF INFORMATIONAL CONTENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO REPRESENTATION THAT THE SITE OR ANY CONTENT, DATA OR MATERIAL IS FREE FROM ERRORS, VIRUSES, BUGS, OR OTHER HARMFUL COMPONENTS. FURTHER, WE MAKE NO REPRESENTATION OR WARRANTY THAT CONTENT, DATA OR MATERIAL PROVIDED ON THE SITE IS APPLICABLE OR APPROPRIATE FOR USE IN LOCATIONS OUTSIDE OF THE UNITED STATES.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>NOTHING IN THESE TERMS OF USE OR IN ANY ORAL OR WRITTEN DESCRIPTION OF THE SITE WILL CONSTITUTE A REPRESENTATION OR WARRANTY WITH RESPECT TO THE SITE.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>YOU ACKNOWLEDGE, BY YOUR USE OF THE SITE, THAT SUCH USE IS AT YOUR SOLE RISK.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF, OR THE INABILITY TO USE, THE SITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Certain state laws do not allow the exclusion of or limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you might have additional rights. In these states, our liability shall be limited to the extent permitted by law.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>UNDER NO CIRCUMSTANCES WHATSOEVER SHALL OUR AGGREGATE LIABILITY RESULTING FROM OR RELATING TO YOUR USE OF THE SITE EXCEED FIVE U.S. DOLLARS (USD $5.00).</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>15. Indemnification.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>You agree to indemnify and hold us, our related entities, and our and their respective successors and assigns harmless from and against any claims, demands, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) that we incur or suffer which relate or arise out of any third party claim concerning your use of the Site, your failure to perform any of your obligations under these Terms of Use, or your breach of any of your representations or warranties made under these Terms of Use.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>16. Links to Other Websites.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>The Site may contain links to other websites. Additionally, other websites may contain links to the Site. We do not review or monitor the websites linked to the Site and we are not responsible for the content or policies of any other websites. Any link established by us does not in any respect whatsoever constitute an endorsement of the other websites or create a relationship between us and the operators of other websites. We disclaim any liability with respect to your use of any other website, and you release us from any liability related to your use of any link to another website or your use of any other website. Where the Site contain a link to another website owned and/or operated by us, such website use may be subject to different and additional terms of use and privacy policy terms and conditions.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>17. Third Party Providers of Functionality.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>We may have functionality in the Site that is provided, in whole or in part, by a third-party service provider. You can usually identify such third-party functionality by the use of a trademark not owned by us on or near the functionality. For such third-party functionality, you will need to review the terms of use and privacy policy for that third party, which in some cases may require you to visit that party's website and locate the terms of use and privacy policy. Your review should include determining whether such terms and policy are acceptable to you. If they are not, do not use the associated functionality on the Site.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>18. Continued Operation, Accessibility, and Maintenance of the Site.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>We may modify, change, suspend, terminate, or discontinue the operation of the Site or the provision of any services therein without notice and we reserve the right to refuse service to anyone at any time, with or without cause.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>19. Submissions.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Any and all comments, suggestions, and similar materials or information that you send or submit to us in connection with the Site, by a Post or otherwise (collectively, “Submissions“), shall become our property; and you hereby transfer, sell, and assign to us all of your right, title, and interest in and to any such Submissions, including without limitation, any and all related patent, copyright, trademark, and other intellectual property rights. We have no obligation of any kind whatsoever to maintain any confidentiality with respect to any such Submissions. We shall be free to use them for any purpose whatsoever without providing you notice or receiving your consent, and without restriction or compensation.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>20. General Provisions.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>English has been used in the preparation of these Terms of Use, and English shall be the controlling language with respect to these Terms of Use and their interpretation. Any and all communications relating to the Site must be in the English language.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Any failure by us to enforce any provision of these Terms of Use shall not be construed as a waiver of any provision or the right to enforce same.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>These Terms of Use shall be governed by the laws of the State of Minnesota without giving effect to any principles or conflicts of laws. These Terms of Use do not, and will not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and us. If any portion of these Terms of Use is held to be invalid, such holding shall not invalidate the other provisions of these Terms of Use..</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>Use of the Site is unauthorized in any jurisdiction that does not give full effect to all provisions of these Terms of Use, including without limitation this paragraph and the warranty disclaimers and liability exclusions above. Use of the Site is unauthorized in any jurisdiction where all or any portion of the Site may violate any legal requirements and you agree not to access the Site in any such jurisdiction.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>You are responsible for compliance with applicable laws.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>21. Dispute Resolution.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>a. Any dispute, controversy, or claim arising out of or relating to your relationship with Healthy Contributions, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory and expressly including any claims for violation of any state consumer protection act or the federal Telephonic Consumer Protection Act or any state or international law equivalents (collectively, “Claims”), shall be resolved by binding arbitration. The sole exception is that you and we retain the right to pursue in small claims court any Claim that is within that court's jurisdiction and proceeds on an individual basis. Aside from this sole exception, all other Claims must be resolved by binding arbitration pursuant to the terms set forth below.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>b. The arbitration shall be administrated by the American Arbitration Association or its successor (“AAA”) in accordance with its Consumer Rules or any other applicable AAA rules. (The AAA’s Consumer Rules are available at <a className={`${styles.link} ${styles['link--blue']}`} href={AMERICAN_ARBITRATION_ASSOCIATION}>{AMERICAN_ARBITRATION_ASSOCIATION}</a>). To the extent there is a conflict between this arbitration provision and the AAA’s Consumer Rules, the arbitration provision controls. In the event that the AAA is no longer in business, then the parties will attempt to agree on an alternative agency. If one cannot be agreed to, then the parties agree that a court of competent jurisdiction may select the arbitration agency for them. The arbitration shall be conducted before a single arbitrator. The arbitration will be conducted in a mutually agreeable location in your state of residence. The decision of the arbitrator shall be final and binding and may be entered as a judgment by any court of competent jurisdiction.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>c. Before initiating an arbitration, you must first send, by certified mail or a nationally recognized courier service like FedEx or UPS, a written Notice of Dispute ("Notice") addressed to: Healthy Contributions, 111 Weir Drive, Woodbury, MN 55125 ("Notice Address"). The Notice must (i) describe the nature and basis of the Claim; and (ii) set forth the specific relief sought. If the you and we do not reach an agreement to resolve the Claim within 60 days after the Notice is received, you may commence an arbitration proceeding with the AAA by submitting the Notice of Dispute to the AAA and initiating the arbitration and sending a copy, by certified mail or courier service, of the initiated arbitration to the Notice Address. During the arbitration, other than informing the arbitrator that the Notice was properly provided, no information regarding any settlement offer made by either party shall be disclosed to the arbitrator. Unless prohibited by applicable law, any Claim must be submitted to us in a Notice of Dispute within one (1) year from the occurrence of the facts giving rise to such Claim.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>d. If the AAA determines that your Claim is part of a filing of multiple or mass case filings, then you agree that the AAA Supplementary Rules for Multiple Case Filings apply and you further agree not to oppose any motion by us for an order requiring you to initially advance all filing fees and arbitrator costs. If you succeed on your Claim in any multiple or mass case filing, the arbitrator may award you costs in any amount that would place you in an equal position as you had been in if you filed your Claim individually.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>e. Neither party shall pursue any class, collective, or representative claims. Unless the Supplementary Rules for Multiple Case Filings apply, the parties may not consolidate the arbitration with any other proceedings to which we are a party, except for an arbitration in which the parties to this agreement are the sole parties.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>f. The arbitrator shall be entitled to award injunctive relief, if applicable, specific to you and to award damages. To the extent allowed by applicable law, the parties agree that neither party will be liable to the other (whether in contract, tort, negligence, strict liability in tort or by statute or otherwise) for any indirect, special, incidental, consequential, exemplary or punitive damages or for any form of damages other than direct damages. The arbitrator shall award attorney’s fees and costs, including the expenses of the arbitration, to the prevailing party if the arbitrator finds that the non-prevailing party asserted frivolous or bad faith defenses or claims.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>g. Any award of the arbitrator shall have no preclusive effect in any future proceeding, except any future proceeding in which you and we are the sole parties.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>h. The Federal Arbitration Act, applicable federal law, and the laws of the state of Minnesota, without regard to principles of conflict of laws, will govern this agreement and any dispute, controversy, or claim of any sort that might arise between us.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>i. The parties agree that the arbitration proceedings and results are confidential and, except as required by law, neither party nor the arbitrator may disclose the existence, content, or results of any arbitration conducted pursuant to this agreement without the prior written consent of the parties.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>j. For the avoidance of doubt, this agreement applies to Claims involving any of Healthy Contributions’ staff, agents, subsidiaries, affiliates, employees, board of directors, parents, franchisees, predecessors, successors, and assigns, each of which is an intended third-party beneficiary of this agreement. This agreement shall survive the termination of any other contractual or non-contractual relationship between us and you, including any membership relationship you may have with a franchisee.</div>
                    <div className={styles['info-page__primary-content-section-paragraph']}>k. With the exception of subparts (d) and (e) above (the class action waiver and mass action rules), if any part of this arbitration provision is held to be invalid, unenforceable, or illegal, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not in it. If, however, subpart (d) or (e) above (the class action waiver and mass action rules) is held to be invalid, unenforceable or illegal, then the entirety of this arbitration provision shall be null and void, and neither you nor us shall be entitled to arbitrate their dispute. If this agreement is found to be unenforceable on inapplicable for any reason, including because the class action waiver is found invalid, unenforceable, or illegal, then the sole and exclusive venue for the resolution of any Claim shall be the United States District Court for District of Minnesota or, if there is no federal jurisdiction over the action, in the courts of the State of Minnesota located in Minneapolis. Further, you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such Claim that is determined not to be subject to the arbitration provision.</div>
                </section>
                <section className={styles['info-page__primary-content-section']}>
                    <h2 className={styles['info-page__primary-content-section-title']}>22. Contact.</h2>
                    <div className={styles['info-page__primary-content-section-paragraph']}>For questions or comments related to this Site or these Terms of Use, please email us at <a className={`${styles.link} ${styles['link--blue']}`} href={`mailto: ${HEALTHY_CONTRIBUTIONS_INFO}`}>{HEALTHY_CONTRIBUTIONS_INFO}</a> or send us a letter to Healthy Contributions, 111 Weir Drive, Woodbury, MN 55125.</div>
                </section>
            </div>
        </div>
    </div>;
}

export default TermsOfUse;